<style>
    .color-squre {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }
    
    .color-squre-mini {
        height: 10px;
        width: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }
    
    .color-primary {
        position: relative;
    }
    
    .color-primary .color-squre {
        background: #5A4FC4;
    }
    
    .color-secound .color-squre {
        background: #F19A64;
    }
    
    .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px #ccc;
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
    }
    
    .example-box {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;
    }
    
    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
    
    .cdk-drag-placeholder {
        opacity: 0;
    }
    
    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
    
    .example-box:last-child {
        border: none;
    }
    
    .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper" [hidden]="showSkeletonLoader1">
    <div class="card animated fadeIn">
        <!-- TABLE HEADER START-->
        <div *ngIf="this.conditions.showTableHeader" class="card-collapse">
            <div class="row data-filter justify-content-end">
                <div class="col-md-2 text-left">
                    <ng-container *ngIf="this.conditions.search">
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="search" name="search" #search="ngModel" [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="searchData($event)">
                            </div>
                        </form>
                    </ng-container>
                </div>
                <div class="col-md-4">
                    <div class="button-continer text-left">
                        <div class="btn-group" dropdown #dropdown="bs-dropdown" [insideClick]="true">


                            <div>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <div class="dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="width:800px">
                                        <div class="card">
                                            <div class="row">
                                                <form #filterForm="ngForm">
                                                    <div class="col-md-12 row" *ngFor="let i of filteredArr; let index=index">
                                                        <div class="col-md-12">
                                                            <ng-select [items]="filterCols" [(ngModel)]="i.key" bindLabel="colFieldname" bindValue="colFieldname" [ngClass]="((submitted || colFieldname.touched) && colFieldname.invalid)?'has-danger':''" name="colFieldname{{index}}" placeholder="Owner" #colFieldname="ngModel"
                                                                [multiple]="false" required [hideSelected]="true">
                                                            </ng-select>
                                                            <div class="error-block text-left" *ngIf="((submitted || colFieldname.touched) && colFieldname.invalid)">
                                                                Select owner
                                                            </div>
                                                            <ng-select class="ng-select-line" [items]="typeArr" bindValue="value" bindLabel="name" placeholder="Contains" [clearable]='false' [multiple]="false" [dropdownPosition]="'auto'" [closeOnSelect]="true" name="contains{{index}}" #contains="ngModel" required
                                                                [(ngModel)]="i.type" (change)="typeArrChanges(i)">
                                                            </ng-select>
                                                            <div class="error-block text-left" *ngIf="((submitted || contains.touched) && contains.invalid)">
                                                                Select type
                                                            </div>
                                                            <!-- Contains -->
                                                            <div *ngIf="i.type !== 'date'">
                                                                <input type="text" class="form-control" placeholder="" name="value{{index}}" [(ngModel)]="i.value" autocomplete="off" (keydown)=" (i.type == 'greaterThan' || i.type=='lessThan' ? restrictKeyPressService.AllowNumbers($event) : '')" #value="ngModel" required>
                                                                <div class="error-block text-left" *ngIf="((submitted || value.touched) && value.invalid)">
                                                                    Enter value
                                                                </div>
                                                            </div>
                                                            <div *ngIf="i.type === 'date'">
                                                                <input placeholder="Date range picker" type="text" class="form-control" bsDaterangepicker [(ngModel)]="i.dateRange" name="daterange{{index}}" #daterange="ngModel" (bsValueChange)="rangeSelected($event, index)" required>
                                                                <div class="error-block text-left" *ngIf="((submitted || daterange?.touched) && daterange?.invalid)">
                                                                    Select date range
                                                                </div>
                                                            </div>
                                                            <em class="fa fa-plus" (click)="addTask()"></em>
                                                            <em class="fa fa-close" *ngIf="filteredArr.length > 1" (click)="removeTask(index)"></em>
                                                        </div>
                                                    </div>
                                                    <div class="row col-md-12 ml-2">
                                                        <button [ngClass]="condition =='$and'?'btn btn-primary':'btn btn-outline-primary'" (click)="conditionChange('$and')">And</button>
                                                        <button [ngClass]="condition =='$or'?'btn btn-primary':'btn btn-outline-primary'" (click)="conditionChange('$or')">Or</button>
                                                    </div>
                                                    <div class="row col-md-12 ml-2 mb-2 mt-2">
                                                        <button type="button" class="btn btn-primary" (click)="applyLatestFilter(filterForm)">Search</button>
                                                        <button class="btn btn-outline-primary" (click)="clearFilter()">Clear</button>
                                                        <input type="text" class="filtersave" placeholder="what to save this filter?" name="description" #description="ngModel" [(ngModel)]="options.description1" autocomplete="off" required>
                                                        <!-- *ngIf="((submitted1 || description.touched) && description.invalid)" -->
                                                        <div class="error-block text-left" *ngIf="((submitted1 || description.touched || description.dirty) && description.invalid)">
                                                            Enter name
                                                        </div>
                                                        <button class="btn btn-primary" (click)="toSaveFilter(filterForm, options.description1, filterColor)">Save
                                & search</button>
                                                        <span [style.background]="filterColor" [cpPosition]="'bottom-left'" class="color-squre mb-2" [(colorPicker)]="filterColor" [cpOKButton]="true" [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                    </div>
                                                </form>
                                                <!-- Get Filters -->
                                                <div *ngFor="let i of savedFilters" class="save-filter col-md-12 mt-2">
                                                    <span [style.background]="i.color" class="color-squre-mini" [(colorPicker)]="i.color" [disabled]="true" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                    <span class="ml-2" (click)="applyLatestFilter('',i)">{{i.description}}
                            </span>
                                                    <span class="fa fa-close ml-2" aria-hidden="true" tooltip="Delete" placement="bottom" (click)="CallAPIdeleteSaveFilter(i)"></span>
                                                    <span class="fa fa-edit ml-2" aria-hidden="true" tooltip="Edit" placement="bottom" (click)="editFilter(i)"></span>
                                                </div>
                                                <div *ngIf="!savedFilters?.length">
                                                    No save filters Found
                                                </div>
                                                <!-- Get Filters -->

                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 text-md-right">
                    <div class="button-continer text-right">
                        <div class="btn-group" dropdown #dropdown="bs-dropdown" [insideClick]="true">

                            <div>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <div class="dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="width:650px">
                                        <div class="card">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'col-md-12':''">
                                                            <ng-container *ngIf="i.columnVisibility">
                                                                <div class="checkbox">
                                                                    <label>
                                      <input type="checkbox" name="status" (change)="selectColumns(tableConfig?.cols)"
                                        [(ngModel)]="i.isSelected">
                                      <span></span>
                                      {{i.colName}}
                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="Save Template" [(ngModel)]="options.templateName" #templateName="ngModel" [disabled]="!count">
                                                            <!-- <div class="error-block text-left" *ngIf="((submitted  || templateName.touched) && templateName.invalid)">
                                                                {{showErrorService.showError('templateName', templateName.errors)}}
                                                            </div> -->
                                                        </div>

                                                        <button class="btn btn-primary" [disabled]="!count" (click)="saveDownloadTemplate(tableConfig?.cols)">
                                Save Template
                              </button>
                                                        <span [style.background]="color" [cpPosition]="'bottom-left'" class="color-squre mb-2" [(colorPicker)]="color" [cpOKButton]="true" [cpSaveClickOutside]="false" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                        <div class="row">
                                                            <div *ngFor="let i of downloadTemplatesArr" class="save-filter col-md-12 mt-2">
                                                                <span [style.background]="i.color" class="color-squre-mini" [(colorPicker)]="i.color" [disabled]="true" [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                                <span class="ml-2" (click)="showTemplate(i)">{{i.description}}
                                  </span>
                                                                <span class="fa fa-close ml-2" aria-hidden="true" tooltip="Delete" placement="bottom" (click)="CallAPIdeleteDownloadTemplate(i)"></span>
                                                                <span class="fa fa-eye ml-2" aria-hidden="true" tooltip="View" placement="bottom" (click)="showTemplate(i)"></span>
                                                                <span class="fa fa-pencil ml-2" aria-hidden="true" tooltip="Edit" placement="bottom" (click)="editTemplate(i)"></span>
                                                            </div>
                                                            <div *ngIf="!downloadTemplatesArr?.length">
                                                                No Templates Found
                                                            </div>
                                                        </div>
                                                        <div class=" row mt-2">
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2 col-md-2" (click)="downloadTemplate('pdf')" [disabled]="!count">Pdf
                                </button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('excel')" [disabled]="!count">Excel</button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('csv')" [disabled]="!count">CSV</button>
                                                            <button type="button" class="nav-link btn btn-outline-primary ml-2  col-md-2" (click)="downloadTemplate('print')" [disabled]="!count">Print</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>


                        <!-- apply active , inactive and delete on mutiple users -->

                        <ng-container *ngIf="this.conditions.showApplyStatus">
                            <div class="btn-group" dropdown [autoClose]="false" container="body" [isDisabled]="!selectedUserList.length">
                                <button id="button-nested" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-nested">
                    Active<span class="caret"></span>
                  </button>
                                <ul id="dropdown-nested" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-nested">
                                    <li role="menuitem" (click)="actionSelected('Delete')"><a class="dropdown-item" href="javascript:;">Delete</a></li>

                                    <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                        <a dropdownToggle class="dropdown-item dropdown-toggle">Status Change</a>
                                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem" (click)="actionSelected('Active')"><a class="dropdown-item" href="javascript:;">Active</a></li>
                                            <li role="menuitem" (click)="actionSelected('Inactive')"><a class="dropdown-item" href="javascript:;">Inactive</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>


                            <form #statusForm="ngForm" (ngSubmit)="submitStatusForm(statusForm,statusFormData)">
                                <ng-select class="ng-select-line" [items]="statusList" bindLabel="statusFormData?.status" bindValue="statusFormData.status" placeholder="Select" [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'" #status="ngModel" name="status" [(ngModel)]="statusFormData.status"
                                    required>
                                </ng-select>
                                <button type="submit" class="btn btn-primary ml-0 apply_btn">Apply</button>
                            </form>
                        </ng-container>
                        <!-- apply active , inactive and delete on mutiple users -->

                        <!-- show button -->
                        <ng-container *ngIf="this.conditions.showButton && !conditions.modal">
                            <button type="button" class="nav-link  btn btn-primary listlink" [routerLink]="[this.conditions.showButton.routerLink]">
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{this.conditions.showButton.buttonName}}</span>
                </button>
                        </ng-container>
                        <ng-container *ngIf="this.conditions.showButton  && conditions.modal">
                            <button type="button" class="btn btn-primary listlink" (click)="openModal(type)">
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{this.conditions.showButton.buttonName}}</span>
                </button>
                        </ng-container>
                        <!-- show button -->

                        <!-- download CSV and EXCEL -->
                        <ng-container *ngIf="this.conditions.showExport">
                            <div class="dropdown">
                                <button class="nav-link  btn btn-teal dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Import</span>
                  </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="importCSVandExcel('csv')">
                      CSV File</a>
                                    <a class="dropdown-item" (click)="importCSVandExcel('excel')">
                      Excel File
                    </a>
                                </div>
                            </div>
                        </ng-container>

                        <!-- download CSV and EXCEL -->
                    </div>
                </div>
            </div>
        </div>
        <!-- ON COLLAPSE COLUMN HIDE SHOW -->
        <div class="card-body">
            <!-- Data Table Start -->
            <!-- {{tableConfig.cols | json}} -->

            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)" class="custom-table table table-striped" id="table" *ngIf="tableData?.data" aria-describedby="table">
                <thead>
                    <tr *ngIf="tableConfig?.cols?.length">
                        <!-- responsive button -->
                        <!-- <th id="th1-button"></th> -->
                        <!-- responsive button -->
                        <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort" *ngFor="let i of tableConfig?.cols" id="th1" [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                            <ng-container *ngIf="i.type!=='multipleSelection' && i.type!=='switch' && i.type !='columnSettings'">
                                {{i.colName}}
                            </ng-container>
                            <ng-container *ngIf="i.type =='columnSettings' ">

                                <div class=" btn-group" dropdown [insideClick]="true">
                                    <em dropdownToggle class=" fa fa-cog">
                  </em>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                        <div class="card">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                                        <div *ngFor="let setting of colSettingCols" class="example-box" cdkDrag [ngClass]="(setting.columnVisibility)?'col-md-12':''">
                                                            <ng-container *ngIf="setting.drag">
                                                                <div class="checkbox">
                                                                    <label>
                                    <input type="checkbox" name="status" [(ngModel)]="setting.isVisible"
                                      [disabled]="setting.disable">
                                    <span></span>
                                    {{setting.colName}}
                                  </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="text-right filter-btn">
                                                            <div class="col-md-12">
                                                                <button type="submit" class="nav-link btn btn-primary ml-2" (click)="saveColumnSettings(colSettingCols)">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
                            <div *ngIf="i.type=='multipleSelection'" class="checkbox">
                                <label>
                  <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox" (change)="selectAllUser(i)">
                  <span></span>
                </label>
                            </div>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">
                            <!-- <td></td> -->
                            <td [hidden]="!j.isVisible" *ngFor="let j of tableConfig?.cols">
                                <div *ngIf="j.type=='switch'" class="custom-control custom-switch light" (click)="changeStatus(i)">
                                    <input type="checkbox" id="id" class="custom-control-input" [ngModel]="i[j.colFieldname]" (click)="$event.stopPropagation()">
                                    <label for="id" class="custom-control-label"></label>
                                </div>
                                <div *ngIf="j.type=='image'" class="thumb-img">
                                    <span>
                      
                                    </span>
                                </div>
                                <ng-container *ngIf="j.type=='action'">
                                    <div *ngFor="let k of tableConfig?.actions" class="action-icon">
                                        <ng-container *ngIf="k.type === 'icon'">
                                            <span class="cursor">
                                                <em [class]="k.class" aria-hidden="true" [tooltip]="k.tooltip" (click)="performAction(k,i)">
                                                </em>
                                            </span>
                                        </ng-container>
                                        <div *ngIf="k.type === 'button'">
                                            <ng-container>
                                                <button type="button" [class]="k.class" (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="j.type =='symbol'" class="align-center">
                                    <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                    <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                </div>
                                <div *ngIf="j.type=='text'">
                                    {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] | date:'dd MMM yyy') : i[j.colFieldname] ? i[j.colFieldname] : '--')}}
                                </div>
                                <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <!-- Data Table End -->
        </div>






        <!-- TABLE FOOTER START-->
        <div class="card-footer" *ngIf="this.conditions.showTableFooter">
            <div class="custom-pagination d-flex align-items-center justify-content-between" *ngIf="tableData.data.length">
                <div class="d-flex align-items-center">
                    <span class="record-count mr-1">Showing</span>
                    <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList" placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'" [labelForId]="tableData.type">
                    </ng-select>
                    <span class="record-count ml-2">out of {{total}} entries</span>
                </div>
                <div class="pagination-list">
                    <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total" (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next" firstText="&laquo;" lastText="&raquo;">
                    </pagination>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
        <!-- TABLE FOOTER END-->
    </div>
</div>