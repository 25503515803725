<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <i class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></i>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="resetPassSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="resetPassSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>

<!--Start Login Wrapper-->
<div class="login-wrapper" style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-4">
                                        <h4 class="mb-2 mt-2">Reset Your Password</h4>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <form (ngSubmit)="resetPassForm.form.valid && onResetPassword(resetPassForm)" #resetPassForm="ngForm">
                                            <div class="form-group mb-4 position-relative">
                                                <label>Password<sup class="bx bxs-star danger"></sup></label>
                                                <input [type]="fieldTextType ? 'text' : 'password'" ngModel id="password-field" type="password" name="password" class="form-control" #password="ngModel" placeholder="Password" [ngClass]="{ 'is-invalid': resetPassForm.submitted && password.invalid }" required
                                                    minlength="6">
                                                <span toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                    'fa-eye-slash': !fieldTextType,
                                                    'fa-eye': fieldTextType
                                                  }" (click)="toggleFieldTextType()"></span>
                                                <div *ngIf="resetPassForm.submitted && password.invalid" class="invalid-feedback">
                                                    <div *ngIf="password.errors.required">Please enter password</div>
                                                    <div *ngIf="password.errors.minlength">Please enter valid 6 digit Password</div>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary glow position-relative btn-block">
													Reset My Password<i class="icon-arrow bx bx-right-arrow-alt"></i>
												</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/forgot-password.webp" type="image/webp">
                                    <source srcset="assets/images/forgot-password.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/forgot-password.png" alt="forgot-password" width="300">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!--End Login Wrapper-->