import { Injectable } from '@angular/core';


import { Observable, of, Subject } from 'rxjs';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';

interface IBroadcastEvent {
  key: any;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class BroadcasterService {
  private eventBus: Subject<IBroadcastEvent>;
 //private eventBus: any;
  constructor() {
    this.eventBus = new Subject<IBroadcastEvent>();
  }
  /***********************************************************************************
  @PURPOSE      : Broadcast Data in key value format
  @PARAMETERS   : key, data
  @RETURN       : NA
  /***********************************************************************************/
  broadcast(key: any, data?: any) {
    this.eventBus.next({ key, data });
  }
  /***********************************************************************************/

  /***********************************************************************************
  @PURPOSE      : On Broadcast of any value this is called
  @PARAMETERS   : key
  @RETURN       : eventBus with data broadcasted
  /***********************************************************************************/
  on<T>(key: any): Observable<T> {
    return this.eventBus.asObservable()
    .filter((event) => event.key === key)
    .map((event) => event.data as T);
  }
  /***********************************************************************************/

  /***********************************************************************************
  @PURPOSE      : unsubscribe
  @PARAMETERS   : NA
  @RETURN       : NA
  /***********************************************************************************/
  unsubscribe() {
    this.eventBus.unsubscribe();
  }
  /***********************************************************************************/
}
