<aside class="sidebar">
    <div class="sidebar-inner-wrapper">

        <div class="sidebar-heading d-flex align-items-end justify-content-between w-100">
            <a class="navbar-brand">
                <div class="brand-logo">
                    <img class="img-fluid" src="assets/images/brand-logo.svg" alt="branding logo">
                </div>
            </a>
            <span (click)="closeSidebar()" class="sidebar-close-icon"><em class="bx bx-x"></em></span>
        </div>
        <div class="sidebar-menu">
            <ul class="sidebar-menu-list">
                <li class="menu-list-item has-child-submenu level1">
                    <a href="#" class="menu-link">
                        <i class='bx bx-home-alt'></i>
                        <span class="menu-title">Dashboard</span>

                    </a>

                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="subUserMenu=!subUserMenu" [ngClass]="subUserMenu ? 'submenu-open' : null">
                    <a style="cursor: pointer;" class="menu-link">
                        <i class='bx bxs-user'></i>
                        <span class="menu-title">Users</span>
                        <i class='bx bxs-chevron-right'></i>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subUserMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a routerLink="/users" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a href="#" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Admin users</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="subContentMenu=!subContentMenu" [ngClass]="subContentMenu ? 'submenu-open' : null">
                    <a style="cursor: pointer;" class="menu-link">
                        <i class='bx bx-link-alt'></i>
                        <span class="menu-title">Content</span>
                        <i class='bx bxs-chevron-right'></i>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subContentMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a href="content-static.html" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Static Pages</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a href="content-blog.html" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Blogs</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a href="faq.html" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">FAQs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" class="menu-link">
                        <i class='bx bx-notification'></i>
                        <span class="menu-title">Email Templates</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.MASTER]" class="menu-link">
                        <i class='bx bx-note'></i>
                        <span class="menu-title">Master</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a href="role-permission.html" class="menu-link">
                        <i class='bx bx-check-circle'></i>
                        <span class="menu-title">Roles</span>
                    </a>
                </li>

                <li class="settign-menu menu-list-item has-child-submenu level1" (click)="subSettingsMenu=!subSettingsMenu" [ngClass]="subSettingsMenu ? 'submenu-open' : null">
                    <a style="cursor: pointer;" class="menu-link">
                        <i class='bx bx-cog'></i>
                        <span class="menu-title">Settings</span>
                        <i class="bx bxs-chevron-right"></i>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subSettingsMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SETTING_GENERAL]" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">General Settings</span>
                            </a>
                        </li>
                        <li class="menu-list-item child-menu level2">
                            <a [routerLink]="[URLConstants.SETTING_SOCIALMEDIA]" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Social Media</span>
                            </a>

                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SETTING_PAYMENTGATEWAY]" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">Payment Gateway SDK Details</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SETTING_SMTPSMS]" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">SMTP</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SETTING_EMAILNOTIFICATION]" class="menu-link">
                                <i class='bx bxs-right-arrow-alt'></i>
                                <span class="menu-title">EMAIL_NOTIFICATION</span>
                            </a>
                        </li>

                    </ul>
                </li>
            </ul>
        </div>
    </div>
</aside>