import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../common/service/theme/theme.service';

@Component({
  selector: 'app-login-with-mobile',
  templateUrl: './login-with-mobile.component.html',
  styleUrls: []
})
export class LoginWithMobileComponent implements OnInit {
  public isSettingOpen = false;
 

  constructor( private themeService: ThemeService) { }

  ngOnInit(): void {
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
 }


toggleTheme() {
  this.themeService.switchTheme();
}

}
