export const callAPIConstants = {
    AdminLogin: 'admin/login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'admin/verifyOTP',
    SendOTP: 'admin/sendOTP',
    ForgotPassword: 'admin/forgotPassword',
    ResetPassword: 'admin/resetPassword',
    AdminLogout: 'admin/logout',
    AdminGetProfile: 'admin/profile',
    AdminEditProfile: 'admin/editProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries:'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    //social media sdk
    getsocialMediaSDK : 'getSocialMediaSDK',
    addSocialMediaSDK:'addSocialMediaSDK',
    //social media link
    getSocialMediaLinks:'getSocialMediaLinks',
    addSocialMediaLinks:'addSocialMediaLinks',
   //global setiing 
    addGlobalSettings:'addGlobalSettings',
    getGlobalSettings:'getGlobalSettings',
    //payment setting
    getPaymentDetails:"getPaymentDetails",
    addPaymentDetails:"/addPaymentDetails",
    //smtp setting
    addSMTPAndSMSSettings:"addSMTPAndSMSSettings",
    getSMTPAndSMSSettings:"getSMTPAndSMSSettings",
    //email notification
    getEmailNotificationSettings:"getEmailNotificationSettings",
    addEmailNotificationSettings:"addEmailNotificationSettings",

    // email-template
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmailColumnData: 'getEmailColumnValues',
    // EmailColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail'

 };
