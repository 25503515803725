import { Component, OnInit } from '@angular/core';
import { URLConstants } from '../../../common/constants/routerLink-constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  public subUserMenu: boolean = false;
  public subContentMenu: boolean = false;
  public subSettingsMenu: boolean = false;



  constructor() { }

  ngOnInit(): void {
  }

  closeSidebar() {
  // var  sidebar = document.getElementsByClassName('sidebar');
  // sidebar[0].style.display = 'none';
  // }
  var  sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
  sidebar[0].style.display = 'none';
  }

}
