export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    RESET_PASS: '/reset-password',
    SIGNUP: '/register',
    DASHBOARD: '/dashboard',
    OTP: '/enter-otp/',
    

    /***** Authorized Pages *****/
    CHANGE_PASS: '/change-password',
    MY_PROFILE: '/my-profile',
    USERS: '/users',
    MASTER: '/master',

    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',

    // Email Templates
    EMAIL_TEMPLATE_list: '/email-template',
    EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',


};
